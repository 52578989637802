<template>
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-11">
            <div class="tab-content">
                <div class="tab-pane fade show active" id="empresa" role="tabpanel"
                    aria-labelledby="empresa-tab">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group row">
                                <label for="disenio"
                                    class="col-sm-4 col-form-label text-right">Diseño:
                                </label>
                                <div class="col-sm-8">
                                    <multiselect
                                        id="disenio"
                                        v-model="modelo"
                                        track-by="descripcion"
                                        label="descripcion"
                                        placeholder="Seleccione un diseño"
                                        :options="model_print"
                                        :disabled="!editMode"
                                        :searchable="true">
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group row">
                                <label for="color"
                                    class="col-sm-4 col-form-label text-right">Color:
                                </label>
                                <div class="col-sm-8">
                                    <v-swatches v-model="color"
                                    swatch-size="35"
                                    :swatches="getColores"
                                    shapes="circles"
                                    :disabled="!editMode"
                                    >
                                    </v-swatches>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VSwatches from 'vue-swatches';
import { sync } from 'vuex-pathify';

export default {
    name: 'TheTabDisenio',
    props: {
        editMode: {
            type: Boolean,
            required: true,
            default: true,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        ...sync('datosEmpresa/edit', [
            'colores',
            'model_print',
            'color',
            'modelo'
        ]),
        getColores() {
            return this.colores.map(function(data) {
                data.disabled = data.disabled == 1
                return data
            });
        }
    },
    components: {
        Multiselect,
        VSwatches,
    },
}
</script>