<template>
    <div>
        <div class="row">
            <div class="col-1"></div>
            <div class="col-11">
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="empresa" role="tabpanel"
                        aria-labelledby="empresa-tab">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group row">
                                    <label for="pais"
                                        class="col-sm-4 col-form-label text-right">Pais
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-8">
                                        <multiselect
                                            :options="pais"
                                            track-by="id"
                                            label="descripcion"
                                            placeholder="pais"
                                            deselectLabel=""
                                            selectLabel=""
                                            :searchable="true"
                                            v-model="paisSelected"
                                            :disabled="!editMode"
                                            >
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="ciudad"
                                        class="col-sm-4 col-form-label text-right">Ciudad:
                                    </label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" id="ciudad"
                                            placeholder="Ingrese la ciudad"
                                            :disabled="true" v-model="ciudad"
                                        >
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="zona_horaria_id"
                                            class="col-sm-4 col-form-label text-right">Zona horaria:
                                        <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <multiselect
                                            id="zona_horaria_id"
                                            track-by="id"
                                            label="descripcion"
                                            v-model="zonaHorariaSelected"
                                            placeholder="Seleccione Zona Horaria"
                                            :options="zonaHorarias"
                                            :disabled="!editMode"
                                            :searchable="true">
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="moneda_principal"
                                        class="col-sm-4 col-form-label text-right">Moneda Principal(SIN):
                                    </label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control"
                                        placeholder="Boliviano-Bs">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="moneda_secundaria"
                                        class="col-sm-4 col-form-label text-right">Moneda Secundaria(SIN):
                                    </label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" placeholder="Dolar-$us">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group row">
                                    <label for="email"
                                        class="col-sm-4 col-form-label text-right">Copia de correo:
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-8">
                                        <input type="email" class="form-control" id="email"
                                            v-model="copiaCorreo"
                                            placeholder="Ingrese un correo"
                                            :disabled="!editMode"
                                        >
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="logotipo"
                                            class="col-sm-4 col-form-label text-right">Logotipo
                                        <br>(200 Anc x 70 Alt):</label>
                                    <div class="col-sm-8">
                                        <input type="file" accept="image/*"
                                                class="form-control-file"
                                                id="logotipo"
                                                :disabled="!editMode"
                                                ref="file"
                                                name="logotipo"
                                                v-on:change="obtenerImagen($event)"/>
                                        <label class="col-form-label" @click="verImagen">Mostrar Imagen</label>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="activacion_gemgloo"
                                        class="col-sm-4 col-form-label text-right">Activacion Gemgloo:
                                    </label>
                                    <div class="col-sm-8">
                                        <date-picker
                                            class="col-sm-9 px-0"
                                            v-model="fechaActivacion"
                                            lang="es"
                                            format="DD/MM/YYYY"
                                            type="date"
                                            value-type="date"
                                            :disabled="!editMode"
                                        ></date-picker>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="sitio_web"
                                        class="col-sm-4 col-form-label text-right">Sitio Web(URL):
                                    </label>
                                    <div class="col-sm-8">
                                        <input type="text"
                                        class="form-control" v-model="sitioWeb">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="nombre_pais"
                                        class="col-sm-4 col-form-label text-right">Nombre del Pais:
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-8">
                                        <input type="text"
                                        class="form-control" v-model="nombrePais">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="sigla_pais"
                                        class="col-sm-4 col-form-label text-right">Sigla de Pais:
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-8">
                                        <input type="text"
                                        class="form-control" v-model="siglaPais">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-3">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="row mb-2" style="margin:auto;">
                            <div class="col-sm-3 text-right">
                            </div>
                            <div class="col-sm-5 text-center">
                                <label class="col-form-label">Codigo de Integracion:</label>
                            </div>
                            <div class="col-sm-2 text-right pr-5">
                                <!-- <label class="col-form-label">Estado:</label> -->
                            </div>
                        </div>
                        <div class="row mb-2" style="margin:auto;">
                            <div class="col-sm-3 text-right">
                                <button class="btn btn-danger" type="button" @click="generarCodigoIntegracion()">Generar</button>
                            </div>
                            <div class="col-sm-5 text-center">
                                <input type="text" class="form-control" v-model="codigoGenerado" disabled>
                                <br>
                                <span style="padding: inherit;" v-if="dominio">www. {{ dominio }}.com</span>
                            </div>
                            <div class="col-sm-4" style="white-space: nowrap;">
                                <button class="btn btn-success mb-2 mr-2" title="copiar" 
                                @click="copiarToken()"
                                :disabled="!this.codigoGenerado ? true : false"
                                >
                                    <i class="nav-icon i-File-Copy"></i>
                                </button>
                                <button class="btn btn-danger mb-2" v-if="this.tokenActivo === false || !this.tokenActivo">Sin conexion</button>
                                <button class="btn btn-success mb-2" v-if="this.tokenActivo === 1">Conectado</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'
import { call, sync } from 'vuex-pathify';
import { util } from '@/plugins/util';

export default {
    name: 'theTabEmpresa',
    props: {
        editMode: {
            type: Boolean,
            required: true,
            default : true,
        },
    },
    data() {
        return {
        };
    },
    methods: {
        async generarCodigoIntegracion() {
            const GENERATE_OK = await this.generarCodigo(this.empresaId);
            if (GENERATE_OK) {
                this.getDatInicial();
            }
        },
        ...call('empresa/generateCodigoIntegracion', [
            'generarCodigo'
        ]),
        ...call('datosEmpresa/edit', [
            'getDatInicial'
        ]),
        copiarToken() {
            let codigo = this.codigoGenerado;
            var aux = document.createElement("div");
            let content = document.createTextNode(codigo);
            aux.appendChild(content);
            document.body.appendChild(aux);
            var seleccion1 = document.createRange();
            seleccion1.selectNodeContents(aux);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(seleccion1);
            try {
                var res = document.execCommand('copy');
                if (res)
                    util.showNotify('¡¡Código copiado al portapapeles!!', 'success');
                else
                    util.showNotify('¡¡Ha fallado el copiado al portapapeles!!', 'error');
            }
            catch(ex) {
                this.$util.showNotify('Se ha producido un error al copiar al portapaples', 'error');
            }
            window.getSelection().removeRange(seleccion1);
            aux.remove();
        },
        obtenerImagen(event) {
            this.logotipo = event.target.files[0];
            this.urlImage = URL.createObjectURL(this.logotipo);
        },
        verImagen() {
            if (this.urlImage) {
                window.open(this.urlImage);
            }
        }
    },
    created() {},
    components: {
        Multiselect,
        DatePicker,
    },
    computed: {
        ...sync('datosEmpresa/edit', [
            'pais',
            'paisSelected',
            'ciudad',
            'zonaHorarias',
            'zonaHorariaSelected',
            'fechaActivacion',
            'sitioWeb',
            'nombrePais',
            'siglaPais',
            'copiaCorreo',
            'empresaId',
            'urlImage',
            'logotipo'
        ]),
        ...sync('empresa/generateCodigoIntegracion', [
            'dominio',
            'codigoGenerado',
            'tokenActivo'
        ]),
    },
    watch: {},
}
</script>