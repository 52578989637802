<template>
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-11">
            <div class="tab-content">
                <div class="tab-pane fade show active" id="proveedor" role="tabpanel"
                    aria-labelledby="proveedor-tab">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group row">
                                <label for="empresa_proveedor"
                                    class="col-sm-4 col-form-label text-right">Empresa Proveedor:
                                </label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" id="empresa_proveedor"
                                        placeholder="Empresa Proveedor"
                                        :disabled="!editMode" v-model="empresaProveedor"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="nombre_sistema"
                                    class="col-sm-4 col-form-label text-right">Nombre Sistema:
                                </label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" id="nombre_sistema"
                                        :disabled="!editMode" v-model="nombreSistema"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="codigo_sistema"
                                    class="col-sm-4 col-form-label text-right">Codigo Sistema:
                                </label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" id="codigo_sistema"
                                        :disabled="!editMode" v-model="codigoSistema"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group row">
                                <label for="codigo_sistema"
                                    class="col-sm-4 col-form-label text-right">Ver Certificado:
                                </label>
                                <div class="col-sm-8">
                                    <button class="btn th-custom-color"
                                    :readonly="!editMode">VER</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sync } from 'vuex-pathify';
export default {
    name: 'TheTabProveedor',
    props: {
        editMode: {
            type: Boolean,
            required: true,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...sync('datosEmpresa/edit', [
            'nombreSistema',
            'codigoSistema',
            'empresaProveedor'
        ]),
    },
}
</script>