<template>
    <div>
        <div class="row" style="background:ghostwhite">
            <div class="col-sm-12">
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="empresa" role="tabpanel"
                        aria-labelledby="empresa-tab">
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group row pl-3">
                                <label for="fecha_desde"
                                    class="col-form-label text-right">Valido desde:
                                </label>
                                <div class="col-sm-8">
                                    <date-picker
                                        id="fecha_desde"
                                        v-model="fechaDesde"
                                        lang="es"
                                        format="DD/MM/YYYY"
                                        type="date"
                                        value-type="date"
                                        width="100%">
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group row pl-3">
                                <label for="fecha_hasta"
                                    class="col-form-label text-right">Valido Hasta:
                                </label>
                                <div class="col-sm-8">
                                    <date-picker
                                        id="fecha_hasta"
                                        v-model="fechaHasta"
                                        lang="es"
                                        format="DD/MM/YYYY"
                                        type="date"
                                        value-type="date"
                                        width="100%">
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group row">
                                <label for="token_delegado"
                                    class="col-form-label text-right">Token Delegado:
                                </label>
                                <div class="col-sm-10" style="margin-left:30px;">
                                    <input type="text" class="form-control"
                                    :disabled="!editMode">
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-6">
                                <label for="certificado"
                                    class="col-form-label text-right">Certificado (Privcert):
                                </label>
                                <br>
                                <textarea name="" id="" cols="70" rows="10"
                                :disabled="!editMode" v-model="certificado"></textarea>
                        </div>
                        <div class="col-sm-6">
                                <label for="llave_privada"
                                    class="col-form-label text-right">Llave Privada (Pubcert):
                                </label>
                                <br>
                                <textarea name="" id="" cols="70" rows="10"
                                :disabled="!editMode" v-model="llavePrivada"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';

import { get, sync } from 'vuex-pathify';

export default {
    name: 'TheTabActivar',
    props: {
        editMode: {
            type: Boolean,
            required: true,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...get('datosEmpresa/edit', [
            
        ]),
        ...sync('datosEmpresa/edit', [
            'certificado',
            'llavePrivada',
            'fechaDesde',
            'fechaHasta',          
        ])
    },
    components: {
        DatePicker,
    },
}
</script>